import { debounce } from './debounce.js';
import stringSimilarity from 'string-similarity';

class Autocomplete {
  constructor(props) {
    this.node = props.homeSearchForm;
    this.inputsForm = this.node.getElementsByClassName('search-form__input');

    this.state = {
      pickup: null,
      delivery: null,
    };
    this.initAutocomplete();

    // https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
    this.autocompleteService = new window.google.maps.places.AutocompleteService();
    // https://developers.google.com/maps/documentation/javascript/geocoding#place-id
    this.geocoderService = new window.google.maps.Geocoder();
  }

  // Autocomplete
  initAutocomplete() {
    for (let input of this.inputsForm) {
      input.addEventListener(
        'input',
        debounce((e) => {
          let currentInputValue = e.target.value;
          let autocompleteWrapper = this.getCurrentAutoCompleteWrapper(
            e.target.id,
          );
          let currentSpinner = this.node.getElementsByClassName(
            `spinner-border ${e.target.id}`,
          )[0];

          if (currentInputValue.length > 2) {
            //search
            currentSpinner.classList.add('--show');
            autocompleteWrapper.style.display = 'block';
            new Promise((resolve, reject) =>
              // Getting predictions
              this.autocompleteService.getPlacePredictions(
                {
                  input: currentInputValue,
                  componentRestrictions: { country: ['ca'] },
                },
                (predictions, status) => {
                  if (
                    status === window.google.maps.places.PlacesServiceStatus.OK
                  )
                    return resolve(predictions);
                  return reject();
                },
              ),
            ).then((predictions) => {
              if (!predictions.length) {
                //show error
                currentSpinner.classList.remove('--show');
                autocompleteWrapper.innerHTML = `<div class="search-form__autocomplete-wrapper_item --error d-flex align-items-center">No results</div>`;
              } else {
                currentSpinner.classList.remove('--show');
                autocompleteWrapper.innerHTML = this.renderAutocompleteResult(
                  predictions,
                  e.target.id,
                  currentInputValue,
                );
                this.initClickOnItem(
                  autocompleteWrapper.getElementsByClassName(
                    'search-form__autocomplete-wrapper_item',
                  ),
                  predictions,
                );
              }
            });
          } else {
            autocompleteWrapper.style.display = 'none';
          }
        }, 300),
      );
      input.addEventListener(
        'focusout',
        debounce((e) => {
          let autocompleteWrapper = this.getCurrentAutoCompleteWrapper(
            e.target.id,
          );
          autocompleteWrapper.style.display = 'none';
        }, 200),
      );
    }
  }

  // Render result autocomplete
  getCurrentAutoCompleteWrapper(inputId) {
    let currentAutocompleteWrapperId = `${
      inputId === 'pickup'
        ? 'pickupAutocomplete'
        : inputId === 'delivery'
        ? 'deliveryAutocomplete'
        : ''
    }`;
    return document.getElementById(`${currentAutocompleteWrapperId}`);
  }

  // Render result autocomplete
  renderAutocompleteResult(predictions, inputId, inputValue) {
    if (predictions) {
      return predictions
        .map((item) => {
          return `
          <div class="search-form__autocomplete-wrapper_item d-flex align-items-center" data-location-name="${
            item.structured_formatting.main_text
          }" data-input-id="${inputId}" data-place-id="${item.place_id}">
            <span>
                ${this.optionToRender(item.description, inputValue)}
            </span>
          </div>`;
        })
        .join('');
    }
  }

  //Refactoring render items
  optionToRender(itm, inputValue) {
    const regExp = new RegExp(`${inputValue}`, 'gi');
    return `${itm}`
      .replace(regExp, (match) => `*${match}*`)
      .split('*')
      .map((itm) => {
        return `${
          regExp.test(itm)
            ? `<span class="bold" style="font-weight: bold">${itm}</span>`
            : `<span>${itm}</span>`
        }`;
      })
      .join('');
  }

  //Set value for pickup and delivery in state
  setValueToStorage(obj, key) {
    this.state = {
      ...this.state,
      [key]: obj,
    };
  }

  getAddressComponentByName(address_components, name) {
    const matchedObj = address_components.find((itm) =>
      itm.types.includes(name),
    );
    return matchedObj || {};
  }

  //Add in input selected value
  initClickOnItem(items) {
    for (let i = 0; i < items.length; i++) {
      items[i].addEventListener('click', () => {
        const currentInput = document.getElementById(
          `${items[i].getAttribute('data-input-id')}`,
        );
        new Promise((resolve, reject) =>
          this.geocoderService.geocode(
            { placeId: items[i].getAttribute('data-place-id') },
            (results, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK)
                return resolve(results);
              return reject();
            },
          ),
        ).then((results) => {
          const locationName = items[i].getAttribute('data-location-name');
          const similarity = stringSimilarity.compareTwoStrings(
            results[0].formatted_address.split(',')[0],
            locationName,
          );
          const address =
            similarity < 0.5
              ? `${locationName}, ${results[0].formatted_address}`
              : results[0].formatted_address;

          currentInput.value = address;

          this.setValueToStorage(
            {
              location: locationName,
              address,
              partial_address: results[0].formatted_address,
              city:
                this.getAddressComponentByName(
                  results[0].address_components,
                  'locality',
                ).short_name || null,
              place_id: items[i].getAttribute('data-place-id'),
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
              province:
                this.getAddressComponentByName(
                  results[0].address_components,
                  'administrative_area_level_1',
                ).short_name || null,
            },
            currentInput.id,
          );
        });
      });
    }
  }
}

export default Autocomplete;
