import Autocomplete from './autocomplete.js';

document.addEventListener('DOMContentLoaded', () => {
  const btnMobileMenu = document.getElementById('btn-mobile-menu');
  const btnCloseMobileMenu = document.getElementById('btn-close-mobile');
  const mobileMenu = document.getElementById('mobile-menu');
  const pickupInput = document.getElementById('pickup');
  const getQuickQuotes = document.getElementsByClassName('getQuickQuote');

  if (document.querySelector('.home')) {
    const homeSearchForm = document.getElementById('homeSearchForm');
    // const nodePriceResult = document.getElementById('priceResult');
    const autocomplete = new Autocomplete({ homeSearchForm });

    pickupInput.focus();

    //Submit home search form
    homeSearchForm.onsubmit = async (e) => {
      e.preventDefault();
      const state = autocomplete.state;
      if (!!state.pickup && !!state.delivery) {
        const request = {
          from: {
            province: state.pickup.province,
            city: state.pickup.city,
            address: state.pickup.address,
            lat: state.pickup.latitude,
            lng: state.pickup.longitude,
            location: state.pickup.location,
          },
          to: {
            province: state.delivery.province,
            city: state.delivery.city,
            address: state.delivery.address,
            lat: state.delivery.latitude,
            lng: state.delivery.longitude,
            location: state.pickup.location,
          },
        };
      }
    };
  }

  for (const getQuickQuote of getQuickQuotes) {
    getQuickQuote.addEventListener('click', function() {
      if (pickupInput) pickupInput.focus();
      else window.location.href = '/';
      mobileMenu.classList.remove('--open');
    });
  }

  //Open mobile menu
  btnMobileMenu.addEventListener('click', () => {
    mobileMenu.classList.add('--open');
  });

  //Close mobile menu
  document.addEventListener('click', (e) => {
    if (e.target.classList.contains('mobile-menu')) {
      mobileMenu.classList.remove('--open');
    }
  });

  btnCloseMobileMenu.addEventListener('click', () => {
    mobileMenu.classList.remove('--open');
  });

  document
    .querySelectorAll('nav a[href^="/' + location.pathname.split('/')[1] + '"]')
    .forEach((itm) =>
      itm.getAttribute('href') == location.pathname
        ? itm.parentElement.classList.add('--active')
        : undefined,
    );
});
